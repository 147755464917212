import React from 'react'
import { Link } from "gatsby"
import Logo from "./../assets/images/OI_logo_WhiteAsset.png"
import Yato from "./../images/A1.svg"
import Milliseconds from "./../images/A3.svg"
import Blanc from "./../images/A2.svg"

function Splash() {
  return (
    <div>
      <section className="min-vh-100 bg-white">
        <div className="container-fluid px-8">
          <div className="d-none d-lg-block">
            <div className="row text-center pt-md-12">
              <div className="col-lg-4 col-12 ">
                <div data-aos="fade-up" data-aos-duration="500">
                  <div>
                    <img className="img-fluid w-35" src={Yato} alt="..." />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
                  <div>
                    <img className="img-fluid w-35" src={Milliseconds} alt="..." />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000">
                  <div>
                    <img className="img-fluid w-35" src={Blanc} alt="..." />
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-between justify-content-center">
              <div className="col-lg-4 col-12">
                <div data-aos="fade-up" data-aos-duration="500">
                  <p className="text-center mb-2 text-dark">
                    Creative Communications & Corporate Events Management
                  </p>
                  <p class="mb-2 font-size-sm text-dark">From integrated brand strategies and advertising campaigns, to media & PR relations, to out-of-the-box, world class events; we dedicate our 360° marketing resources towards steering your brand to maximize audience engagement, and enhance image and impact.</p>

                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
                  <p className="text-center mb-2 text-dark">
                    Creative design, Architectural design
                  </p>
                  <p class="mb-2 font-size-sm text-dark">We develop cutting edge graphic, motion, and architectural designs that breathe life into your brand's vision, making it a reality.</p>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000">
                  <p className="text-center mb-2 text-dark">
                    Interior Design & fittings
                  </p>
                  <p class="mb-2 font-size-sm text-dark">We specialize in residential and non-residential interior facelifts, and the design, supply and installation of next generation interior design solutions; all carefully crafted to make the space reflect your personality.</p> 
                </div>
              </div>
            </div>
            <div className="row align-items-between text-center justify-content-center">
              <div className="col-lg-4 col-12">
                <div data-aos="fade-up" data-aos-duration="500">
                  <p class="font-size-xs text-primary">
                    <Link to="/get-in-touch/">Contact The General Manager</Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
                  <p class="font-size-xs text-primary">
                    <Link to="/get-in-touch/">Contact The General Manager</Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000">
                  <p class="font-size-xs text-primary">
                    <Link to="/get-in-touch/">Contact The General Manager</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="d-lg-none">
            <div className="row align-items-center justify-content-center pt-md-12">
              <div className="col-lg-4 col-12 lift p-5">
                <div data-aos="fade-up">
                  {/* <h2
                    className="h2 mt-4 mb-md-3 text-dark">
                    Yatọ
                  </h2> */}

                  <div>
                    <img className="img-fluid w-50" src={Yato} alt="..." />
                  </div>
                  <p className="mb-2 text-dark">
                    Creative Communications & Corporate Events Management
                  </p>
                  <p class="mb-2 font-size-sm text-dark">From integrated brand strategies and advertising campaigns, to media & PR relations, to out-of-the-box, world class events; we dedicate our 360° marketing resources towards steering your brand to maximize audience engagement, and enhance image and impact.</p>
                  <p class="font-size-xs text-primary">
                    <Link to="/get-in-touch/">Contact The General Manager</Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-12 lift p-5">
                <div data-aos="fade-up">
                  {/* <h2
                    className="h2 mt-4 mb-md-3 text-dark">
                    Milliseconds
                  </h2> */}
                  <div>
                    <img className="img-fluid w-50" src={Milliseconds} alt="..." />
                  </div>
                  <p className="mb-2 text-dark">
                    Creative design, Architectural design
                  </p>
                  <p class="mb-2 font-size-sm text-dark">We develop cutting edge graphic, motion, and architectural designs that breathe life into your brand's vision, making it a reality.</p>
                  <p class="font-size-xs text-primary">
                    <Link to="/get-in-touch/">Contact The General Manager</Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-12 lift p-5">
                <div data-aos="fade-up">
                  {/* <h2
                    className="h2 mt-4 mb-md-3 text-dark">
                    Blanc
                  </h2> */}
                  <div>
                    <img className="img-fluid w-50" src={Blanc} alt="..." />
                  </div>
                  <p className="mb-2 text-dark">
                    Interior Design & fittings
                  </p>
                  <p class="mb-2 font-size-sm text-dark">We specialize in residential and non-residential interior facelifts, and the design, supply and installation of next generation interior design solutions; all carefully crafted to make the space reflect your personality.</p> 
                  <p class="font-size-xs text-primary">
                    <Link to="/get-in-touch/">Contact The General Manager</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Splash
